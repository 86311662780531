.header {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.title {
  font-size: 2rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

.headerLinks {
  align-items: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}

@media (min-width: 600px) {
  .title {
    font-size: 4rem;
  }

  .headerLinks {
    display: flex;
    white-space: nowrap;
  }
}

.link {
  width: 100%;
}

.link > a {
  text-decoration: none;
  color: #4a6274;
  padding-left: 1rem;
}

.link > a:hover {
  color: #e2725a;
}
