.timeline {
  height: 100%;
}

.timelineSectionDescription {
  padding: 1rem;
}

.link > a {
  text-decoration: none;
  color: #4a6274;
  font-style: italic;
}

.link > a:hover {
  font-weight: bold;
}
