.bulletPointOuter {
  display: flex;
  padding-left: 1rem;
  text-decoration: none;
  margin: 0.5rem;
}

.bullet {
  align-items: flex-start;
}
