.app {
  background-color: #f9ddd2;
  height: 100%;
  min-width: 300px;
  max-width: 100vw;
  overflow: scroll;
  font-family: "Roboto Slab", serif;
  color: #4a6274;
  padding: 2rem;
  /* scroll-padding: 2rem 0 0 50px; */
}

.appBoundary {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: 2px solid #79aeb2;
  border-radius: 12px;
  background-color: #faf4f0;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  overflow: hidden;
}
