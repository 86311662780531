.timelineSectionHeader {
  background-color: #4a6274;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 4px solid lightblue;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
}

.timelineSectionHeaderText {
  font-weight: 700;
  padding-right: 0.5rem;
  padding-top: 0.1rem;
}

.img {
  min-width: 90px;
  max-width: 100px;
  max-height: 40px;
}
